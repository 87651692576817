.jumbotron {
  text-align: center;
  overflow: hidden;
  background-image: url('/src/images/coffee1.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin: 0;
  border: none;
}

.div-border {
  border-top: 1px white solid;
}

.header-padding {
  padding-top: 70px;
}

.header-p {
  padding-top: 20px;
  font-size: 1.5em;
}
