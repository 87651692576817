#popular-drinks {
  font-size: 1.3em;
}

.card-title {
  font-size: 1.5em;
}

#res-cards {
  display: none;
}

.div-border {
  border-top: 1px white solid;
}

@media screen and (max-width: 1258px) {
  #reg-cards {
    display: none;
  }

  #res-cards {
    display: flex;
  }
}
