.nav-menu {
  background: #212529;
  margin: 0;
  overflow: hidden;
}

.nav-ul {
  margin: 0;
  padding: 0;
  display: flex;
  float: right;
}

.nav-ul-li {
  display: inline-block;
  list-style-type: none;
}

.nav-top-a {
  color: #baa375;
  padding: 20px 35px;
  display: block;
  line-height: 3em;
  font-size: 1.4em;
  text-decoration: none;
}

.nav-top-a:hover {
  color: white;
  text-decoration: none;
}

.res-menu {
  display: none;
}

/* Put this mq at 750px in the end */
@media screen and (max-width: 1000px) {
  #dropdown-content {
    display: none;
  }

  .nav-ul-li {
    display: none;
  }

  .res-menu {
    display: flex;
    float: right;
    padding: 20px 35px;
  }

  .res-menu-content {
    position: absolute;
    top: 100px;
    right: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .res-menu-content ul {
    margin: 0;
    padding: 10px 35px;
    width: 100%;
  }

  .res-menu-content li {
    margin: 0;
    padding: 15px 0;
    border-bottom: dotted;
    border-width: 1px;
    border-color: #494949;
  }

  .res-menu-content a {
    float: right;
    text-decoration: none;
    color: #baa375;
    font-size: 1.4rem;
  }

  .res-menu-content a:hover {
    color: white;
  }
}
