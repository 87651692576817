#footer {
  text-align: center;
  overflow: hidden;
  background-image: url('/src/images/coffee-beans.jpg');
  background-size: cover;
  background-position: center;
  height: 50vh;
  margin: 0;
  font-size: 1.1rem;
}

.div-border {
  border-top: 1px white solid;
}

.footer-floater {
  background: rgba(0, 0, 0, 0.7);
  width: 90vw;
  height: 30vh;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  padding-top: 10px;
}
.footer-floater h3 {
  padding: 7px 0;
  color: #baa375;
}

.footer-floater p {
  font-size: 1.5em;
  font-weight: bold;
  padding: 0 15px;
  color: white;
}

.footer-floater i {
  padding-bottom: 10px;
}

.footer-floater a {
  padding: 0 15px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.87);
}

.floater-div {
  display: inline-block;
  width: 30%;
  padding: 30px 10px;
  vertical-align: middle;
}

.footer-bg {
  background-color: #212529;
  display: flex;
  color: #baa375;
  font-weight: bold;
  font-size: 1.5em;
  padding: 25px;
  width: 100%;
  height: auto;
}

.footer-bottom-p {
  margin: auto;
}

#res-icons {
  display: none;
}

@media screen and (max-width: 1195px) {
  #footer {
    font-size: 15px;
  }

  .footer-floater {
    width: 60vw;
    height: 95%;
  }

  .floater-div {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0;
  }
}

@media screen and (max-height: 1130px) {
  #footer {
    font-size: 14px;
  }

  .footer-floater {
    width: 80%;
    height: 290px;
  }

  .floater-div {
    display: inline-block;
    width: 30%;
    height: 50%;
    padding: 20px 0;
    vertical-align: middle;
  }
}

@media screen and (max-height: 1130px) and (max-width: 950px) {
  .floater-div {
    margin: 30px 0px;
    width: 50%;
  }

  .sched-div {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  #footer {
    font-size: 12px;
  }

  .footer-floater {
    width: 80%;
    height: 310px;
  }

  .floater-div {
    display: inline;
    margin: 0;
    padding: 5px 0;
    align-items: center;
    justify-content: center;
    width: 80%;
  }

  .delivery-div {
    display: none;
  }
}

@media screen and (max-width: 380px) {
  .footer-floater {
    margin: auto;
  }
}

@media screen and (max-width: 950px) and (max-height: 1130px) {
  #icons {
    display: none;
  }

  #res-icons {
    display: inline-block;
  }
}
