#about-us {
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.about-us-container {
  padding: 35px 80px;
}

.about-us-h2 {
  padding: 15px;
}

.darkener {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.about-us-bg {
  text-align: center;
  overflow: hidden;
  background-image: url('/src/images/pouring-coffee.jpg');
  background-size: cover;
  background-position: center;
  height: 60vh;
}

.div-border {
  border-top: 1px white solid;
}

@media screen and (max-width: 380px) {
  .about-us-container {
    padding: 20px 20px;
  }
}

@media screen and (min-width: 900px) {
  #about-us {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 1100px) {
  .about-us-container {
    padding: 30px 80px;
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1300px) {
  .about-us-container {
    padding: 50px 130px;
  }
}

@media screen and (min-width: 1850px) {
  .about-us-container {
    padding: 60px 400px;
  }
}

@media screen and (max-height: 1150px) {
  .one-of-kind-h3 {
    display: none;
  }

  .about-us-container p {
    font-size: 18px;
  }
}

@media screen and (max-height: 840px) {
  .res-p {
    display: none;
  }

  .about-us-container p {
    font-size: 19px;
  }
}

@media screen and (max-width: 650px) {
  .about-us-bg {
    height: 80vh;
  }
}
