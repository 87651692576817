html {
  margin: 0;
  padding: 0;
  max-height: 100vh;
}

div h1,
h2,
h3,
h4,
h5 {
  font-family: 'Cormorant Unicase', serif;
}

div {
  font-family: 'Cormorant Garamond', serif;
}
